import cn from 'classnames';
import React, { forwardRef, useEffect, useMemo, useRef } from 'react';

import styles from './index.css';

import { CheckboxInputProps, CheckboxInputStatus } from '../../types';

import {
  CheckboxCheckedIcon,
  CheckboxIndeterminateIcon,
  CheckboxOutlineIcon,
} from './icons';

// eslint-disable-next-line react/display-name
const CheckboxInput = forwardRef<HTMLInputElement, CheckboxInputProps>(
  (
    {
      indeterminate = false,
      onChange,
      checked,
      disabled = false,
      error = false,
      className = '',
      tabIndex = 0,
      testId = '',
      ...restProps
    }: CheckboxInputProps,
    ref
  ): JSX.Element => {
    const CheckboxDictionaryIcon = useMemo(
      (): {
        [M in CheckboxInputStatus]: JSX.Element;
      } => ({
        [CheckboxInputStatus.DEFAULT]: <CheckboxOutlineIcon />,
        [CheckboxInputStatus.CHECKED]: <CheckboxCheckedIcon />,
        [CheckboxInputStatus.INDETERMINATE]: <CheckboxIndeterminateIcon />,
      }),
      []
    );

    const status: CheckboxInputStatus = useMemo((): CheckboxInputStatus => {
      if (checked) {
        return CheckboxInputStatus.CHECKED;
      }
      if (indeterminate) {
        return CheckboxInputStatus.INDETERMINATE;
      }
      return CheckboxInputStatus.DEFAULT;
    }, [indeterminate, checked]);

    const inputIconClasses = cn({
      [styles.containerIcon]: true,
      [styles.disabled]: disabled,
      [styles.error]: error && !disabled,
      [styles[status]]: true,
      [className]: className,
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const inputRef: React.RefObject<HTMLInputElement> =
      ref ?? useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (inputRef && inputRef.current) {
        inputRef.current.indeterminate = indeterminate;
      }
    }, [indeterminate]);

    return (
      <span className={inputIconClasses} tabIndex={tabIndex}>
        <input
          ref={inputRef}
          data-testid={testId}
          className={styles.input}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          data-error={error && !disabled}
          onChange={onChange}
          tabIndex={-1}
          {...restProps}
        />
        {CheckboxDictionaryIcon[status]}
      </span>
    );
  }
);

export default CheckboxInput;

import React from 'react';

export default function FinancingDashboardIcon(props: SVGProps): JSX.Element {
  const { title = 'FinancingDashboard', ...restProps } = props;

  return (
    <svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 35.1C27.4441 35.1 35.1 27.4441 35.1 18C35.1 8.55593 27.4441 0.9 18 0.9C8.55593 0.9 0.9 8.55593 0.9 18C0.9 27.4441 8.55593 35.1 18 35.1ZM18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z"
      />
      <path d="M17.3249 11.7563H9.8999V10.7438H17.3249V11.7563Z" />
      <path d="M9.8999 14.4563H17.3249V13.4438H9.8999V14.4563Z" />
      <path d="M18.6749 17.1563L26.0999 17.1563V16.1438L18.6749 16.1438V17.1563Z" />
      <path d="M17.3249 25.2563H9.8999V24.2438H17.3249V25.2563Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.0999 25.2563L18.6749 25.2563V24.2438H21.2573C20.7934 23.7892 20.4539 23.2081 20.2947 22.5563H18.6749V21.5438H20.1976C20.2265 20.9269 20.4132 20.3512 20.7181 19.8563H18.6749V18.8438H21.6565C22.2408 18.4192 22.9599 18.1688 23.7374 18.1688C25.6946 18.1688 27.2812 19.7554 27.2812 21.7125C27.2812 22.7621 26.8249 23.705 26.0999 24.3539V25.2563ZM23.7374 24.2438C22.3394 24.2438 21.2062 23.1105 21.2062 21.7125C21.2062 20.3146 22.3394 19.1813 23.7374 19.1813C25.1354 19.1813 26.2687 20.3146 26.2687 21.7125C26.2687 23.1105 25.1354 24.2438 23.7374 24.2438Z"
      />
      <path d="M17.3249 22.5563H9.8999V21.5438H17.3249V22.5563Z" />
      <path d="M9.8999 19.8563H17.3249V18.8438H9.8999V19.8563Z" />
      <path d="M17.3249 17.1563H9.8999V16.1438H17.3249V17.1563Z" />
    </svg>
  );
}

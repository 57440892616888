import React, { forwardRef } from 'react';

import { CheckboxProps } from './types';

import { CheckboxInput, CheckboxLabel, CheckboxWrapper } from './elements';

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      indeterminate = false,
      error = false,
      checked,
      disabled,
      name,
      onBlur,
      onChange,
      children,
      className = '',
      id,
      testId = '',
      ...restProps
    }: CheckboxProps,
    ref
  ): JSX.Element => (
    <CheckboxWrapper
      className={`checkbox-wrapper-${className}`}
      testId={`checkbox-wrapper-${testId}`}
    >
      <CheckboxInput
        className={`checkbox-input-${className}`}
        testId={`checkbox-input-${testId}`}
        ref={ref}
        id={id}
        name={name}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        indeterminate={indeterminate}
        error={error}
        checked={checked}
        {...restProps}
      />
      {children && (
        <CheckboxLabel
          className={`checkbox-label-${className}`}
          testId={`checkbox-content-${testId}`}
          htmlFor={id}
          disabled={disabled}
        >
          {children}
        </CheckboxLabel>
      )}
    </CheckboxWrapper>
  )
);

Checkbox.displayName = 'Checkbox';
export default Checkbox;
